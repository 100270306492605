export const menuData = [
  {
    title: "About",
    link: "/About",
  },
  {
    title: "Resume",
    link: "/Resume",
  },
  {
    title: "Projects",
    link: "/projects",
  },
];
